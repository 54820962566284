import { useMemo } from "react";
import {
  AlignmentGoalFragmentFragment,
  GoalAlignmentDialogGoalFragmentFragment,
  GoalScope,
} from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import { goalAlignmentPageCount } from "@apps/goal-alignment/goal-alignment";
import useFetchAlignedGoals from "@apps/goal-alignment/use-fetch-alignment-goals";
import useLabel from "@apps/use-label/use-label";
import Loading from "@components/loading/loading";
import { dateRangeToDateArray } from "@helpers/helpers";

import MiniGoalAlignmentTreeItem from "./mini-goal-alignment-tree-item";

const MiniGoalAlignmentTree = ({
  goalToAlignTo,
  allowedGoalScopes,
  onSelectGoal,
  alignmentType,
}: {
  allowedGoalScopes: GoalScope[];
  goalToAlignTo: GoalAlignmentDialogGoalFragmentFragment;
  alignmentType: "child" | "parent";
  onSelectGoal: (selectedGoal: AlignmentGoalFragmentFragment) => void;
}) => {
  const label = useLabel();
  const variables = useMemo(
    () => ({
      first: goalAlignmentPageCount,
      goalDueBetweenDates: dateRangeToDateArray({
        range: DateRangeEnum.thisQuarter,
      }),
      goalScopes: [GoalScope.Organization, GoalScope.Team, GoalScope.Personal], // we don't show career goals in alignment view
      hasSearch: false,
    }),
    []
  );
  const { goals, loading, goalsById, childGoalIdsByParentId } =
    useFetchAlignedGoals({ variables });

  const topLevelGoals = useMemo(
    () => goals.filter((artifact) => !artifact.parentGoalId),
    [goals]
  );

  return (
    <div>
      <div className="font-medium text-gray-500 text-xs flex items-center gap-2">
        {label("goal", { capitalize: true })} alignment
        {goals.length > 0 && loading && <Loading mini size={4} />}
      </div>
      <div>
        {goals.length === 0 && loading ? (
          <Loading />
        ) : (
          <div className="mt-1 -mx-4 text-sm">
            {topLevelGoals.map((topLevelGoal) => (
              <MiniGoalAlignmentTreeItem
                key={topLevelGoal.id}
                goal={topLevelGoal}
                indent={0}
                goalsById={goalsById}
                childGoalIdsByParentId={childGoalIdsByParentId}
                onSelectGoal={onSelectGoal}
                allowedGoalScopes={allowedGoalScopes}
                goalToAlignTo={goalToAlignTo}
                alignmentType={alignmentType}
                canCauseParentLoops={false}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MiniGoalAlignmentTree;
