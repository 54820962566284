import { useMemo } from "react";
import { useParams } from "react-router-dom";

import useLabel from "@apps/use-label/use-label";
import Heading from "@components/heading/heading";
import { useLink } from "@components/link/link";

import CompetencyForm from "./competency-form";

const CompetencyFormPage = ({ organizationId }: { organizationId: number }) => {
  const label = useLabel();
  const link = useLink();
  const params = useParams<{ competencyId: string }>();
  const competencyId = useMemo(() => {
    if (params.competencyId?.match(/\d+/)) {
      return parseInt(params.competencyId);
    }
    return null;
  }, [params]);
  const isNew = competencyId === null;

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <Heading
          small
          title={`${!isNew ? "Edit" : "New"} ${label("competency")}`}
        />
      </div>

      <CompetencyForm
        organizationId={organizationId}
        competencyId={competencyId}
        onCompetencySaved={() =>
          link.redirect(`/settings/organization/${organizationId}/competencies`)
        }
        onCancel={() =>
          link.redirect(`/settings/organization/${organizationId}/competencies`)
        }
      />
    </div>
  );
};

export default CompetencyFormPage;
