import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { batchClient } from "@graphql/client";

import getEmbedKpiQuery from "../graphql/get-embed-kpi-query";
import KpiView from "./kpi-view";

export const singleKpiViewLimit = 4;

const SingleKpiView = ({
  node,
  deleteNode,
  selected,
  updateAttributes,
}: {
  node: any;
  deleteNode: () => void;
  selected: boolean;
  updateAttributes: (attributes: any) => void;
}) => {
  const [summaryPeriod, setSummaryPeriod] = useState(
    node.attrs.summaryPeriod || null
  );
  const [viewMode, setViewMode] = useState(node.attrs.viewMode || "table");

  const { data, loading, refetch } = useQuery(getEmbedKpiQuery, {
    variables: {
      kpiId: node.attrs.id,
      summaryPeriod: summaryPeriod,
      first: singleKpiViewLimit + 3,
    },
    client: batchClient,
    onError: onNotificationErrorHandler(),
  });
  const kpi = data?.kpi;

  useEffect(() => {
    if (summaryPeriod === null && kpi && kpi.defaultSummaryPeriod) {
      setSummaryPeriod(kpi.defaultSummaryPeriod);
    }
  }, [kpi, setSummaryPeriod]);

  useEffect(() => {
    try {
      if (summaryPeriod) {
        updateAttributes({ ...node.attrs, summaryPeriod: summaryPeriod });
      }
    } catch {}
  }, [summaryPeriod]);

  useEffect(() => {
    try {
      if (viewMode) {
        updateAttributes({ ...node.attrs, viewMode: viewMode });
      }
    } catch {}
  }, [viewMode]);

  useEffect(() => {
    if (
      node.attrs.summaryPeriod !== summaryPeriod &&
      node.attrs.summaryPeriod
    ) {
      setSummaryPeriod(node.attrs.summaryPeriod);
    }
    if (node.attrs.viewMode !== viewMode && node.attrs.viewMode) {
      setViewMode(node.attrs.viewMode);
    }
  }, [node]);

  return (
    <div>
      {loading && !kpi?.currentMeasurement ? (
        <Loading mini size="5" />
      ) : (
        <KpiView
          kpi={kpi}
          deleteNode={deleteNode}
          selected={selected}
          summaryPeriod={summaryPeriod}
          setSummaryPeriod={setSummaryPeriod}
          setViewMode={setViewMode}
          viewMode={viewMode}
          onAddValue={refetch}
          isEmbeddedInKpiGroup={false}
        />
      )}
    </div>
  );
};

export default SingleKpiView;
