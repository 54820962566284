import useLabel from "@apps/use-label/use-label";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";

import CompetencyForm from "./competency-form";

const CompetencyFormModal = ({
  organizationId,
  competencyId,
  onClose,
  onCompetencySaved,
}: {
  organizationId: number;
  competencyId: number;
  onClose: () => void;
  onCompetencySaved: (competencyId: number) => void;
}) => {
  const label = useLabel();
  return (
    <Modal fullWidth open onClose={onClose} alignedTop>
      <div className="p-6 flex flex-col gap-4">
        <ModalTitle onClose={onClose}>{`Edit ${label("competency", {
          capitalize: true,
        })}`}</ModalTitle>

        <CompetencyForm
          organizationId={organizationId}
          competencyId={competencyId}
          onCancel={onClose}
          onCompetencySaved={(id) => {
            onCompetencySaved(id);
            onClose();
          }}
        />
      </div>
    </Modal>
  );
};

export default CompetencyFormModal;
