import { InformationCircleIcon, PencilIcon } from "@heroicons/react/outline";
import { useState } from "react";
import {
  CompetencyCriteriaUniqueness,
  CompetencyNode,
  OrganizationCompetencyCriteriaFragment,
} from "types/graphql-schema";

import { competencyInfo } from "@apps/org-settings/constants";
import Button, { buttonTheme } from "@components/button/button";
import Layout from "@components/layout/layout";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import CompetencyFormModal from "../competency-form-modal";
import CriteriaEditor from "../criteria-editor";
import { roleColWidth, roleLevelIterator, stickyColWidth } from "../helpers";
import StickyColumnShadow from "../sticky-column-shadow";
import { CareerTrackFormRole } from "./career-track-form";

const EmptyCriteriaCell = () => (
  <td
    className={classNames(
      roleColWidth,
      "p-4 border align-center text-center align-middle bg-gray-50 text-gray-300"
    )}
  >
    No role
  </td>
);

const CareerTrackCompetencyRow = ({
  organizationId,
  competency,
  levelCount,
  roles,
  onAddCriteria,
  onDeleteCriteria,
  onCriteriaChange,
  onCompetenciesUpdated,
}: {
  organizationId: number;
  competency: Pick<
    CompetencyNode,
    "id" | "title" | "criteriaUniqueness" | "appliesTo"
  > &
    OrganizationCompetencyCriteriaFragment;
  levelCount: number;
  roles: CareerTrackFormRole[];
  onAddCriteria: (roleUuid: string, level: number) => void;
  onDeleteCriteria: (roleUuid: string, criteriaUuid: string) => void;
  onCriteriaChange: (
    roleUuid: string,
    criteriaUuid: string,
    content: string
  ) => void;
  onCompetenciesUpdated: () => void;
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  return (
    <>
      {isEditModalOpen && (
        <CompetencyFormModal
          organizationId={organizationId}
          competencyId={competency.id}
          onClose={() => setIsEditModalOpen(false)}
          onCompetencySaved={onCompetenciesUpdated}
        />
      )}
      <tr key={competency.id} className="h-52 align-top">
        <td
          className={classNames(
            stickyColWidth,
            "p-4 border border-l-0 sticky left-0 bg-white z-10"
          )}
        >
          <div className="flex justify-between">
            <div className="font-medium">{competency.title}</div>
            <Button
              theme={buttonTheme.text}
              icon
              className={classNames(
                "text-inherit",
                Layout.headerIconButtonClassName
              )}
              onClick={() => setIsEditModalOpen(true)}
            >
              <PencilIcon className="h-4 w-4" />
            </Button>
          </div>
          <div className="flex justify-between gap-2 bg-gray-100 mt-2 p-2 text-xs rounded-md">
            <div className="w-5/6">{competencyInfo(competency)}</div>
            <InformationCircleIcon className="text-gray-400 h-4 w-4" />
          </div>

          <StickyColumnShadow />
        </td>
        {roleLevelIterator(levelCount).map((level) => {
          const role = roles.find((role) => role.level === level);

          if (!role) {
            return <EmptyCriteriaCell key={level} />;
          }

          const criteria = role
            ? competency.criteriaUniqueness ===
              CompetencyCriteriaUniqueness.SameAcrossRoles
              ? assertEdgesNonNull(competency.criteria).filter(
                  (criteria) => criteria.level === level
                )
              : role.criteriaByCompetencyId[competency.id] ?? []
            : [];

          return (
            <td className={classNames(roleColWidth, "p-4 border")} key={level}>
              <CriteriaEditor
                criteria={criteria}
                editable={
                  competency.criteriaUniqueness ===
                  CompetencyCriteriaUniqueness.DifferentForEachRole
                }
                onAddCriteria={() => onAddCriteria(role.uuid, level)}
                onDeleteCriteria={(uuid) => onDeleteCriteria(role.uuid, uuid)}
                onCriteriaChange={(uuid, content) =>
                  onCriteriaChange(role.uuid, uuid, content)
                }
              />
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default CareerTrackCompetencyRow;
