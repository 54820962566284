import { useLazyQuery } from "@apollo/client";
import {
  ArrowSmRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import { compact, orderBy } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import {
  AdoptionReportAttribute,
  AdoptionReportMetric,
  GetAdoptionReportQuery,
  GetAdoptionReportQueryVariables,
  ReportFilterInput,
  ReportFilterType,
} from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Heading from "@components/heading/heading";
import Loading from "@components/loading/loading";
import Select, { SelectOption } from "@components/select/select";
import Table, {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
  TableSortDir,
} from "@components/table/table";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";
import { assertNonNull, dateRangeToDateArray } from "@helpers/helpers";

import getAdoptionReportQuery from "../graphql/get-adoption-report-query";
import { attributeLabels, metricLabels } from "../helpers";
import CompletionChart from "./adoption-report/completion-chart";

const attributes = [
  AdoptionReportAttribute.Name,
  AdoptionReportAttribute.LastSeen,
];
const metrics = [
  AdoptionReportMetric.OneononeMeetingsAsParticipant,
  AdoptionReportMetric.OneononeMeetingsAsManager,
  AdoptionReportMetric.GoalsCreated,
  AdoptionReportMetric.GoalUpdates,
  AdoptionReportMetric.CareerGoalUpdates,
  AdoptionReportMetric.RecognitionsGiven,
  AdoptionReportMetric.RecognitionsReceived,
  AdoptionReportMetric.AssessmentsAsTarget,
  AdoptionReportMetric.ActionItemsCreated,
  AdoptionReportMetric.DecisionsCreated,
  AdoptionReportMetric.DocumentsCreated,
];

const completionChartMetrics = [
  [
    AdoptionReportMetric.GoalsCreated,
    AdoptionReportMetric.GoalUpdates,
    AdoptionReportMetric.CareerGoalUpdates,
  ],
  [
    AdoptionReportMetric.OneononeMeetingsAsParticipant,
    AdoptionReportMetric.OneononeMeetingsAsManager,
  ],
  [
    AdoptionReportMetric.RecognitionsGiven,
    AdoptionReportMetric.RecognitionsReceived,
  ],
  [
    AdoptionReportMetric.ActionItemsCreated,
    AdoptionReportMetric.DecisionsCreated,
    AdoptionReportMetric.DocumentsCreated,
  ],
];

const attributeValueFormatter = (
  attribute: AdoptionReportAttribute,
  value: string | null
) => {
  if (value && attribute === AdoptionReportAttribute.LastSeen) {
    return moment(value).format("YYYY-MM-DD");
  }
  return value;
};

enum ExtraDateRangeEnum {
  all = "all",
  custom = "custom",
}
type DateRangeType = ExtraDateRangeEnum | DateRangeEnum;

const AdoptionReport = () => {
  const organization = currentOrganizationVar();
  const label = useLabel();
  const [report, setReport] = useState<
    GetAdoptionReportQuery["adoptionReport"] | null
  >(null);
  const [selectedFilter, setSelectedFilter] =
    useState<UserComboboxOption | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeType>(
    ExtraDateRangeEnum.all
  );
  const [reportDateRangeDates, setReportDateRangeDates] = useState<string[]>([
    moment().format("YYYY-MM-DD"),
    moment().add(1, "month").format("YYYY-MM-DD"),
  ]);
  const [chartCaroselIndex, setChartCaroselIndex] = useState(0);
  const [sortKey, setSortKey] = useState<
    AdoptionReportAttribute | AdoptionReportMetric
  >(AdoptionReportAttribute.Name);
  const [sortDir, setSortDir] = useState<TableSortDir>("asc");

  const {
    options: recipientOptions,
    query,
    setQuery,
  } = useUserComboboxQuery({
    types: [UserComboboxOptionType.USER, UserComboboxOptionType.TEAM],
  });

  const dueBetweenDatesOptions = useMemo(() => {
    const dateRangeOptions = Object.values(DateRangeEnum).map((dateRange) => ({
      value: dateRange,
      label: label(dateRange, { capitalize: true }),
      description: dateRangeToDateArray({
        range: dateRange,
        quarterStartMonth: organization.quarterStartMonth,
      })
        .map((date) => moment(date).format("ll"))
        .join(" - "),
    }));
    return [
      {
        value: ExtraDateRangeEnum.all,
        label: `All time`,
      },
      ...dateRangeOptions,
      {
        value: ExtraDateRangeEnum.custom,
        label: "Custom date range",
      },
    ];
  }, [label, organization]);

  const [getReport, { loading: isLoadingReport }] = useLazyQuery<
    GetAdoptionReportQuery,
    GetAdoptionReportQueryVariables
  >(getAdoptionReportQuery, { onError: onNotificationErrorHandler() });

  const filters: ReportFilterInput[] = useMemo(
    () =>
      compact([
        selectedFilter && {
          type:
            selectedFilter.type === UserComboboxOptionType.USER
              ? ReportFilterType.UserId
              : ReportFilterType.TeamId,
          values: [`${selectedFilter.id}`],
        },
        selectedDateRange !== ExtraDateRangeEnum.all && {
          type: ReportFilterType.DateRange,
          values: reportDateRangeDates,
        },
      ]),
    [reportDateRangeDates, selectedDateRange, selectedFilter]
  );

  useEffect(() => {
    getReport({
      variables: {
        organizationId: organization.id,
        attributes,
        metrics,
        filters,
      },
      onCompleted: (response) => {
        setReport(response.adoptionReport);
      },
    });
  }, [getReport, organization, filters]);

  const handleChangeRecipient = useCallback((option?: UserComboboxOption) => {
    setSelectedFilter(option ? option : null);
  }, []);

  const handleChangeCreatedDateRange = useCallback(
    (option: SelectOption<DateRangeType>) => {
      if (
        option.value !== ExtraDateRangeEnum.custom &&
        option.value !== ExtraDateRangeEnum.all
      ) {
        setReportDateRangeDates(
          dateRangeToDateArray({
            range: option.value,
            quarterStartMonth: organization.quarterStartMonth,
          })
        );
      }
      setSelectedDateRange(option.value);
    },
    [organization]
  );

  const handleChangeCreatedStartDate = useCallback(
    (newDate: Date | null) => {
      if (newDate) {
        const newCreatedBetweenDates = [...reportDateRangeDates];
        newCreatedBetweenDates[0] = moment(newDate).format("YYYY-MM-DD");
        setReportDateRangeDates(newCreatedBetweenDates);
      }
    },
    [reportDateRangeDates]
  );

  const handleChangeCreatedEndDate = useCallback(
    (newDate: Date | null) => {
      if (newDate) {
        const newCreatedBetweenDates = [...reportDateRangeDates];
        newCreatedBetweenDates[1] = moment(newDate).format("YYYY-MM-DD");
        setReportDateRangeDates(newCreatedBetweenDates);
      }
    },
    [reportDateRangeDates]
  );

  const handleClickSorting = useCallback(
    (newSortKey: AdoptionReportAttribute | AdoptionReportMetric) => () => {
      if (newSortKey !== sortKey) {
        setSortKey(newSortKey);
      } else {
        setSortDir(sortDir === "asc" ? "desc" : "asc");
      }
    },
    [sortDir, sortKey]
  );

  const sortedRows = useMemo(() => {
    return report
      ? orderBy(
          report.data.map((row) => assertNonNull(row)),
          (row) => {
            const attr = attributes.findIndex((a) => a === sortKey);
            const metric = metrics.findIndex((m) => m === sortKey);
            if (attr >= 0) {
              return row.attributeValues[attr];
            }
            if (metric >= 0) {
              return row.metricValues[metric];
            }
          },
          [sortDir]
        )
      : [];
  }, [report, sortDir, sortKey]);

  return (
    <div className="w-full p-6">
      <Heading small title="Usage & engagement" />

      <div className="mt-4 flex items-start gap-4">
        <div className="w-64">
          <UserCombobox
            onChangeValue={handleChangeRecipient}
            options={recipientOptions}
            value={selectedFilter}
            placeholder="All users"
            clearable={!!selectedFilter}
            query={query}
            onChangeQuery={setQuery}
            onClearValue={handleChangeRecipient}
            searchPlaceholder="Search..."
          />
        </div>
        <div className="w-48">
          <Select<DateRangeType>
            onChange={handleChangeCreatedDateRange}
            options={dueBetweenDatesOptions}
            value={selectedDateRange}
          />
          {selectedDateRange === ExtraDateRangeEnum.custom &&
            reportDateRangeDates && (
              <div className="mt-1 flex justify-end gap-0.5 items-center">
                <ReactDatePicker
                  placeholderText="Start date"
                  selected={moment(reportDateRangeDates[0]).toDate()}
                  onChange={handleChangeCreatedStartDate}
                  dateFormat="MMM d, yyyy"
                  className={classNames("w-24 tracking-tight py-0.5 text-xs")}
                  maxDate={moment(reportDateRangeDates[1]).toDate()}
                />
                <ArrowSmRightIcon className="text-gray-500 w-4 h-4" />
                <ReactDatePicker
                  selected={moment(reportDateRangeDates[1]).toDate()}
                  placeholderText="End date"
                  onChange={handleChangeCreatedEndDate}
                  dateFormat="MMM d, yyyy"
                  className={classNames("w-24 tracking-tight py-0.5 text-xs")}
                  minDate={moment(reportDateRangeDates[0]).toDate()}
                />
              </div>
            )}
        </div>
      </div>

      <div
        className="grid gap-4 place-items-center"
        style={{
          gridTemplateColumns: `50px repeat(${completionChartMetrics[chartCaroselIndex].length}, 1fr) 50px`,
        }}
      >
        <div className="flex items-center justify-end h-full">
          <Button
            className="p-2"
            theme={buttonTheme.lightBlue}
            disabled={chartCaroselIndex === 0}
            onClick={() => setChartCaroselIndex(chartCaroselIndex - 1)}
          >
            <ChevronLeftIcon className="h-6 w-6" />
          </Button>
        </div>
        {completionChartMetrics[chartCaroselIndex].map((metric) => (
          <CompletionChart key={metric} metric={metric} filters={filters} />
        ))}
        <div className="flex items-center h-full">
          <Button
            className="p-2"
            theme={buttonTheme.lightBlue}
            disabled={chartCaroselIndex === completionChartMetrics.length - 1}
            onClick={() => setChartCaroselIndex(chartCaroselIndex + 1)}
          >
            <ChevronRightIcon className="h-6 w-6" />
          </Button>
        </div>
      </div>
      <div className="mt-4">
        {isLoadingReport && (
          <div className="flex-1 flex justify-center p-10">
            <Loading />
          </div>
        )}
        {report && !isLoadingReport && (
          <TableContainer scroll>
            <Table>
              <TableHeadRow>
                {attributes.map((attr) => (
                  <TableHeadCell
                    key={attr}
                    sorted={sortKey === attr ? sortDir : undefined}
                    onClick={handleClickSorting(attr)}
                  >
                    {attributeLabels[attr]}
                  </TableHeadCell>
                ))}
                {metrics.map((metric) => (
                  <TableHeadCell
                    key={metric}
                    sorted={sortKey === metric ? sortDir : undefined}
                    onClick={handleClickSorting(metric)}
                  >
                    {metricLabels(label)[metric]}
                  </TableHeadCell>
                ))}
              </TableHeadRow>
              <TableBody>
                {sortedRows.map((reportRow, rowIndex) => (
                  <TableBodyRow key={rowIndex}>
                    {attributes.map((attr, index) => (
                      <TableBodyCell key={index}>
                        {attributeValueFormatter(
                          attr,
                          reportRow.attributeValues[index] ?? ""
                        )}
                      </TableBodyCell>
                    ))}
                    {metrics.map((_, index) => (
                      <TableBodyCell key={index}>
                        {reportRow.metricValues[index] ?? ""}
                      </TableBodyCell>
                    ))}
                  </TableBodyRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default AdoptionReport;
