import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import {
  GetMeetingGoalsNewPageQueryQuery,
  GetMeetingGoalsNewPageQueryQueryVariables,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import getMeetingGoalsQuery from "@apps/meeting-new/graphql/get-meeting-goals-query";
import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Layout from "@components/layout/layout";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

const MeetingGoalCollapsible = ({
  expandedUiPreferenceKey,
  title,
  variables,
}: {
  expandedUiPreferenceKey: keyof UiPreferenceCache;
  title: string;
  variables: GetMeetingGoalsNewPageQueryQueryVariables;
  newSidebarDesign?: boolean;
  roundedBottom?: boolean;
}) => {
  const { url } = useRouteMatch();
  const { uiPreferenceCache } = useUiPreferenceCache();

  const { data, fetchMore, loading } = useQuery<
    GetMeetingGoalsNewPageQueryQuery,
    GetMeetingGoalsNewPageQueryQueryVariables
  >(getMeetingGoalsQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...variables,
      fetchData: !!uiPreferenceCache[`${expandedUiPreferenceKey}`],
    },
    onError: onNotificationErrorHandler(),
  });

  const handleClickMore = () => {
    fetchMore({
      variables: {
        after: data?.artifacts?.pageInfo.endCursor,
        merge: true,
      },
    });
  };

  const totalCount = data?.artifacts?.totalCount;
  const goals = data?.artifacts?.edges
    ? assertEdgesNonNull(data.artifacts)
    : [];

  return (
    <Layout.SidebarSubSection
      expandedUiPreferenceKey={expandedUiPreferenceKey}
      title={title}
      loading={goals.length === 0 && loading}
      count={totalCount}
    >
      <Layout.SidebarSubSectionList
        loading={loading}
        hasNextPage={!!data?.artifacts?.pageInfo.hasNextPage}
        onClickMore={handleClickMore}
      >
        {goals.map((goal) =>
          goal.__typename === "GoalArtifactNode" ? (
            <Layout.SidebarSubSectionListItem key={goal.id}>
              <Artifact artifact={goal} urlPrefix={url} />
            </Layout.SidebarSubSectionListItem>
          ) : null
        )}
      </Layout.SidebarSubSectionList>
    </Layout.SidebarSubSection>
  );
};

export default MeetingGoalCollapsible;
