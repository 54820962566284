import { compact, differenceBy, maxBy, orderBy, sum, uniqBy } from "lodash";
import { defer } from "lodash";
import { max } from "lodash";
import moment from "moment";
import { MouseEvent, useEffect, useState } from "react";
import { useRef } from "react";

import { hourInPixels, topPixelMargin } from "@apps/calendar/calendar";
import MeetingDialog from "@apps/meeting-dialog/meeting-dialog";
import { classNames } from "@helpers/css";
import { assertNonNull } from "@helpers/helpers";

import Event from "./event";

type EventType = { id: number; startDatetime: string; endDatetime: string };

export const eventsHaveOverlap = (event1: EventType, event2: EventType) => {
  return (
    moment(event2.startDatetime).isBefore(event1.endDatetime) &&
    moment(event2.endDatetime).isAfter(event1.startDatetime)
  );
};

const getOverlappingEvents = (events: EventType[], event: EventType) => {
  return events.filter((e) => eventsHaveOverlap(e, event));
};

const recurseAllOverlappingEventsOfOverlappingEvents = (
  events: EventType[],
  event: EventType,
  initialMemo: EventType[] = []
): EventType[] => {
  return getOverlappingEvents(events, event).reduce(
    (memo, overlappingEvent) => {
      const matchingEvent = memo.find(({ id }) => overlappingEvent.id === id);
      if (matchingEvent) {
        return memo;
      }
      const newMemo = [...memo, overlappingEvent];
      return newMemo.concat(
        recurseAllOverlappingEventsOfOverlappingEvents(
          events,
          overlappingEvent,
          newMemo
        )
      );
    },
    initialMemo
  );
};

const getGroupedOverlappingEvents = (
  overlappingEvents: EventType[],
  index = 0,
  parent: any = null
): any => {
  // events on same level and not overlapping each other
  const eventsOnSameLevel = overlappingEvents.reduce(
    (memo: { keep: EventType[]; ignore: EventType[] }, overlap: EventType) => {
      // return memo if event overlap with previous one
      if (
        memo.keep.length > 0 &&
        eventsHaveOverlap(memo.keep[memo.keep.length - 1], overlap)
      ) {
        return memo;
      }

      // ignore event if event on same level do not overlap with parent
      if (parent && !eventsHaveOverlap(parent, overlap)) {
        return {
          ...memo,
          ignore: [...memo.ignore, overlap],
        };
      }

      // add event to memo
      const overlapToAdd = {
        ...overlap,
        children: [],
        index: index + 1,
        path: compact((parent?.path || []).concat(overlap.id)),
        parent,
      };
      return {
        ...memo,
        keep: memo.keep.concat(overlapToAdd),
      };
    },
    { keep: [], ignore: [] }
  );
  const eventsOnSameLevelAndNotOverlappingEachOther = eventsOnSameLevel.keep;
  const eventsToIgnore = eventsOnSameLevel.ignore;

  // if no data we stopped execution of recurring loop
  if (eventsOnSameLevelAndNotOverlappingEachOther.length === 0) {
    return eventsOnSameLevelAndNotOverlappingEachOther;
  }
  const newOverlappingEvents = differenceBy(
    overlappingEvents,
    eventsOnSameLevelAndNotOverlappingEachOther.concat(eventsToIgnore),
    "id"
  );
  if (newOverlappingEvents.length === 0) {
    return eventsOnSameLevelAndNotOverlappingEachOther;
  }

  return eventsOnSameLevelAndNotOverlappingEachOther.map((overlap) => ({
    ...overlap,
    children: getGroupedOverlappingEvents(
      newOverlappingEvents,
      index + 1,
      overlap
    ),
  }));
};

const getLongestPathInGroupedOverlaps = (groupedOverlaps: any) => {
  return groupedOverlaps.reduce((_: number, overlap: any) => {
    return Math.max(
      overlap.path.length,
      getLongestPathInGroupedOverlaps(overlap.children)
    );
  }, 0);
};

export class OverlappingEvents {
  events: EventType[];

  cache = {
    getMaxOverlapCountOfEvent: {} as { [key: string]: any },
    getMaxOverlapCountOfEventsOverlappingEvent: {} as { [key: string]: any },
    getAllOverlappingEventsOfOverlappingEvents: {} as { [key: string]: any },
    getLongestPathOfEventInGroupedOverlappingEvents: {} as {
      [key: string]: any;
    },
    getEventPositioning: {} as { [key: string]: any },
  };

  constructor(events: EventType[]) {
    this.events = events;
  }

  getMaxOverlapCountOfEvent(event: EventType) {
    if (this.cache.getMaxOverlapCountOfEvent[event.id]) {
      return this.cache.getMaxOverlapCountOfEvent[event.id];
    }
    const overlappingEvents = getOverlappingEvents(this.events, event);
    const groupedOverlaps = getGroupedOverlappingEvents(
      overlappingEvents,
      0,
      null
    );
    this.cache.getMaxOverlapCountOfEvent[event.id] =
      getLongestPathInGroupedOverlaps(groupedOverlaps);
    return this.cache.getMaxOverlapCountOfEvent[event.id];
  }

  getMaxOverlapCountOfEventsOverlappingEvent(event: EventType) {
    if (this.cache.getMaxOverlapCountOfEventsOverlappingEvent[event.id]) {
      return this.cache.getMaxOverlapCountOfEventsOverlappingEvent[event.id];
    }
    const allOverlappingEvents =
      this.getAllOverlappingEventsOfOverlappingEvents(event);
    const results = uniqBy(allOverlappingEvents, "id").map((e) =>
      this.getMaxOverlapCountOfEvent(e)
    );
    this.cache.getMaxOverlapCountOfEventsOverlappingEvent[event.id] = Math.max(
      ...results
    );
    return this.cache.getMaxOverlapCountOfEventsOverlappingEvent[event.id];
  }

  findLongestPathOfEventInGroupedOverlappingEvents(
    groupedOverlaps: any,
    event: EventType
  ) {
    const paths = groupedOverlaps.reduce((memo: any, overlap: any) => {
      const childrenPath =
        this.findLongestPathOfEventInGroupedOverlappingEvents(
          overlap.children,
          event
        ) || [];
      const validPaths = compact([overlap.path, memo, childrenPath]).filter(
        (path) => path.includes(event.id)
      );
      return maxBy(validPaths, (path) => path.length);
    }, []);
    return paths;
  }

  getAllOverlappingEventsOfOverlappingEvents(event: EventType): EventType[] {
    if (this.cache.getAllOverlappingEventsOfOverlappingEvents[event.id]) {
      return this.cache.getAllOverlappingEventsOfOverlappingEvents[event.id];
    }
    this.cache.getAllOverlappingEventsOfOverlappingEvents[event.id] = uniqBy(
      recurseAllOverlappingEventsOfOverlappingEvents(this.events, event),
      "id"
    );
    return this.cache.getAllOverlappingEventsOfOverlappingEvents[event.id];
  }

  getLongestPathOfEventInGroupedOverlappingEvents(event: EventType) {
    // return cached data
    if (this.cache.getLongestPathOfEventInGroupedOverlappingEvents[event.id]) {
      return this.cache.getLongestPathOfEventInGroupedOverlappingEvents[
        event.id
      ];
    }

    const allOverlappingEvents =
      this.getAllOverlappingEventsOfOverlappingEvents(event);
    const sortedOverlappingEvents = orderBy(
      allOverlappingEvents,
      ["startDatetime", "duration"],
      ["asc", "desc"]
    );
    const groupedOverlaps = getGroupedOverlappingEvents(
      sortedOverlappingEvents,
      0,
      null
    );

    this.cache.getLongestPathOfEventInGroupedOverlappingEvents[event.id] =
      this.findLongestPathOfEventInGroupedOverlappingEvents(
        groupedOverlaps,
        event
      );
    return this.cache.getLongestPathOfEventInGroupedOverlappingEvents[event.id];
  }

  getEventPositioning(event: EventType) {
    // return cached data
    if (this.cache.getEventPositioning[event.id]) {
      return this.cache.getEventPositioning[event.id];
    }

    const maxCount = this.getMaxOverlapCountOfEventsOverlappingEvent(event);
    const longestPathWithEvent =
      this.getLongestPathOfEventInGroupedOverlappingEvents(event);
    const index = longestPathWithEvent.findIndex(
      (id: number) => id === event.id
    );
    const pathsOfEventInLongestPath = longestPathWithEvent.map(
      (pathId: number) => {
        const matchingEvent = assertNonNull(
          this.events.find((e) => e.id === pathId)
        );
        return this.getLongestPathOfEventInGroupedOverlappingEvents(
          matchingEvent
        );
      }
    );

    const widths = pathsOfEventInLongestPath
      .reduce((memo: any, path: any, i: number) => {
        const memoAdd = sum(memo);
        const width = maxCount - memoAdd - (path.length - i) + 1;
        return memo.concat(width);
      }, [])
      .map((width: number) => (width * 100) / maxCount);

    this.cache.getEventPositioning[event.id] = {
      left: sum(widths.slice(0, index)),
      width: widths[index],
    };
    return this.cache.getEventPositioning[event.id];
  }

  getAugmentedEvents() {
    const sortedEvents = orderBy(
      this.events,
      ["startDatetime", "duration"],
      ["asc", "desc"]
    );
    return sortedEvents.map((event) => {
      return {
        ...event,
        ...this.getEventPositioning(event),
      };
    });
  }
}

const DayColumn = ({
  selectedDay,
  events,
  day,
}: {
  selectedDay: any;
  events: any;
  day: any;
}) => {
  const [now, setNow] = useState(moment().format());
  const containerRef = useRef<HTMLDivElement>(null);
  const [time, setTime] = useState({ hours: moment().hour(), minutes: 0 });
  const [showCreateMeetingModal, setShowCreateMeetingModal] = useState(false);
  const overlappingEvents = new OverlappingEvents(events);
  const augmentedEvents = overlappingEvents.getAugmentedEvents();

  const handleClickCreateMeeting = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === containerRef.current) {
      const { clientY } = e;
      const { parentElement } = e.target as HTMLDivElement;
      const top =
        assertNonNull(parentElement?.getBoundingClientRect().top) +
        topPixelMargin;
      const pixelY = max([clientY - top, 0]) || 0;
      const hours = Math.floor(pixelY / hourInPixels);
      const minutes = (pixelY / hourInPixels - hours) * 60;
      setTime({ hours, minutes: minutes >= 30 ? 30 : 0 });
      defer(() => {
        setShowCreateMeetingModal(true);
      });
    }
  };

  const handleCloseDialog = () => setShowCreateMeetingModal(false);

  const date = moment(day)
    .hour(time.hours)
    .startOf("hour")
    .minute(time.minutes);
  const beforeSelectedDay = moment(selectedDay).subtract(1, "day");
  const afterSelectedDay = moment(selectedDay).add(1, "day");
  const formOptions = {
    startDatetime: date.clone().format(),
    endDatetime: date.clone().add(30, "minutes").format(),
  };

  const todayLineY = date.clone().isSame(moment(), "day")
    ? topPixelMargin +
      moment(now).hours() * hourInPixels +
      (moment(now).minutes() / 60) * hourInPixels
    : 0;

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(moment().format());
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={classNames(
        `mt-px relative flex-col`,
        date.isSame(selectedDay, "day")
          ? "flex flex-col"
          : date.isSame(beforeSelectedDay, "day") ||
            date.isSame(afterSelectedDay, "day")
          ? "hidden @lg/calendar:flex flex-col"
          : "hidden @4xl/calendar:flex flex-col"
      )}
      onClick={handleClickCreateMeeting}
      ref={containerRef}
      aria-label="Weekly column container"
    >
      {showCreateMeetingModal && (
        <MeetingDialog onClose={handleCloseDialog} formOptions={formOptions} />
      )}

      {todayLineY > 0 && (
        <div
          className="absolute left-0 right-0 h-0.5 bg-red-500 z-1 pointer-events-none"
          style={{ top: `${todayLineY}px` }}
        />
      )}
      {augmentedEvents.map((augmentedEvent) => (
        <Event
          event={augmentedEvent}
          key={augmentedEvent.id}
          width={`${augmentedEvent.width}%`}
          left={`${augmentedEvent.left}%`}
        />
      ))}
    </div>
  );
};

export default DayColumn;
