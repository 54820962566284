import { gql } from "@apollo/client";

export default gql`
  fragment organizationCompetencyCriteria on CompetencyNode {
    criteria {
      edges {
        node {
          id
          content
          level
        }
      }
    }
  }
  query getOrganizationCompetencies($organizationId: Int!) {
    competencies(organizationId: $organizationId) {
      edges {
        node {
          id
          title
          description
          appliesTo
          appliesToManagementRoles
          appliesToIcRoles
          appliesToTeams {
            edges {
              node {
                id
                title
              }
            }
          }
          appliesToCareerTracks {
            edges {
              node {
                id
              }
            }
          }
          criteriaUniqueness
          ...organizationCompetencyCriteria
        }
      }
    }
  }
`;
