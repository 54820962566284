import { useMutation } from "@apollo/client";
import {
  ArrowRightIcon,
  SelectorIcon,
  SparklesIcon,
} from "@heroicons/react/outline";
import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";
import { TextOptions } from "@tiptap-pro/extension-ai";
import { isTextSelection } from "@tiptap/core";
import { Editor } from "@tiptap/react";
import { delay } from "lodash";
import { ReactElement, useCallback, useRef } from "react";
import {
  RiBold,
  RiCheckboxLine,
  RiFontColor,
  RiFormatClear,
  RiItalic,
  RiLink,
  RiLinkUnlink,
  RiMessage2Line,
  RiPaintBrushLine,
  RiStrikethrough,
} from "react-icons/ri";
import {
  TbColumnInsertLeft,
  TbColumnInsertRight,
  TbFreezeColumn,
  TbFreezeRow,
  TbRowInsertBottom,
  TbRowInsertTop,
  TbTarget,
  TbTrash,
} from "react-icons/tb";
import store from "store2";
import { hideAll } from "tippy.js";
import { v4 as uuidv4 } from "uuid";

import { refetchActionItemsInCollapsibleContainers } from "@apps/action-items-collapsible/helpers";
import getMeetingGoalsQuery from "@apps/meeting-new/graphql/get-meeting-goals-query";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import createOrUpdateArtifactMutation from "@components/wysiwyg/extensions/artifacts/graphql/create-or-update-artifact-mutation";
import { artifactType } from "@helpers/constants";
import { classNames } from "@helpers/css";

import { toggleLink } from "../helpers";
import { BubbleMenu } from "./bubble-menu/bubble-menu-component";

const buttonClassName = "bg-white px-1.5 py-1 text-gray-700 hover:bg-gray-100";
const tableButtonClassName = "bg-white px-1.5 py-1 text-gray-500";
const activeButtonClassName = "bg-blue-600 text-white";

const highlightColours = [
  { value: "#f3f4f6", label: "Gray" },
  { value: "#fee2e2", label: "Red" },
  { value: "#ffedd5", label: "Orange" },
  { value: "#fef3c7", label: "Yellow" },
  { value: "#d1fae5", label: "Green" },
  { value: "#dbeafe", label: "Blue" },
  { value: "#e0e7ff", label: "Purple" },
  { value: "#ede9fe", label: "Violet" },
  { value: "#ffe4e6", label: "Pink" },
];

export const AIMenuItems = ({
  editor,
  menuButton,
  automaticallySelectAllContent = false,
  onlyTextPrompt = false,
}: {
  editor: Editor;
  menuButton: ReactElement;
  automaticallySelectAllContent?: boolean;
  onlyTextPrompt?: boolean;
}) => {
  const aiConfig: TextOptions = {
    stream: false,
    modelName: "gpt-3.5-turbo",
    language: "en",
  };
  const getEditorChain = () =>
    automaticallySelectAllContent
      ? editor.chain().focus().selectAll()
      : editor.chain();
  return (
    <Menu
      portal
      align="start"
      transition={false}
      className="text-sm not-prose z-dropdown fs-unmask"
      menuButton={menuButton}
    >
      <MenuItem
        className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100 rounded-t-md"
        onClick={() => getEditorChain().aiRephrase(aiConfig).run()}
      >
        Rephrase
      </MenuItem>
      <SubMenu label="Adjust tone">
        <MenuItem
          className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
          onClick={() =>
            getEditorChain().aiAdjustTone("academic", aiConfig).run()
          }
        >
          Academic
        </MenuItem>
        <MenuItem
          className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
          onClick={() =>
            getEditorChain().aiAdjustTone("business", aiConfig).run()
          }
        >
          Business
        </MenuItem>
        <MenuItem
          className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
          onClick={() =>
            getEditorChain().aiAdjustTone("casual", aiConfig).run()
          }
        >
          Casual
        </MenuItem>
        <MenuItem
          className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
          onClick={() =>
            getEditorChain().aiAdjustTone("confident", aiConfig).run()
          }
        >
          Confident
        </MenuItem>
        <MenuItem
          className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
          onClick={() =>
            getEditorChain().aiAdjustTone("conversational", aiConfig).run()
          }
        >
          Conversational
        </MenuItem>
        <MenuItem
          className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
          onClick={() =>
            getEditorChain().aiAdjustTone("creative", aiConfig).run()
          }
        >
          Creative
        </MenuItem>
        <MenuItem
          className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
          onClick={() =>
            getEditorChain().aiAdjustTone("excited", aiConfig).run()
          }
        >
          Excited
        </MenuItem>
        <MenuItem
          className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
          onClick={() =>
            getEditorChain().aiAdjustTone("formal", aiConfig).run()
          }
        >
          Formal
        </MenuItem>
        <MenuItem
          className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
          onClick={() =>
            getEditorChain().aiAdjustTone("informative", aiConfig).run()
          }
        >
          Informative
        </MenuItem>
        <MenuItem
          className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
          onClick={() =>
            getEditorChain().aiAdjustTone("inspirational", aiConfig).run()
          }
        >
          Inspirational
        </MenuItem>
        <MenuItem
          className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
          onClick={() =>
            getEditorChain().aiAdjustTone("narrative", aiConfig).run()
          }
        >
          Narrative
        </MenuItem>
        <MenuItem
          className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
          onClick={() =>
            getEditorChain().aiAdjustTone("objective", aiConfig).run()
          }
        >
          Objective
        </MenuItem>
        <MenuItem
          className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
          onClick={() =>
            getEditorChain().aiAdjustTone("persuasive", aiConfig).run()
          }
        >
          Persuasive
        </MenuItem>
      </SubMenu>
      <MenuItem
        className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
        onClick={() => getEditorChain().aiShorten(aiConfig).run()}
      >
        Shorten
      </MenuItem>
      <MenuItem
        className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
        onClick={() => getEditorChain().aiExtend(aiConfig).run()}
      >
        Extend
      </MenuItem>
      <MenuItem
        className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
        onClick={() => getEditorChain().aiSummarize(aiConfig).run()}
      >
        Summarize
      </MenuItem>
      <MenuItem
        className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
        onClick={() => getEditorChain().aiSimplify(aiConfig).run()}
      >
        Simplify
      </MenuItem>
      <MenuItem
        className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
        onClick={() => getEditorChain().aiFixSpellingAndGrammar(aiConfig).run()}
      >
        Fix spelling
      </MenuItem>
      <MenuItem
        className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
        onClick={() => getEditorChain().aiEmojify(aiConfig).run()}
      >
        Enrich with 🙂
      </MenuItem>
      <MenuItem
        className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
        onClick={() => getEditorChain().aiTldr(aiConfig).run()}
      >
        Tl;dr
      </MenuItem>
      {!onlyTextPrompt && (
        <MenuItem
          className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100 rounded-b-md"
          onClick={() =>
            getEditorChain()
              .aiImagePrompt({ modelName: "dall-e-2", size: "512x512" })
              .run()
          }
        >
          Generate image
        </MenuItem>
      )}
    </Menu>
  );
};

export default function CustomBubbleMenu({
  editor,
  enableComment = false,
  meetingId,
  organizationId,
  topicId,
  onCreateCommentUuid,
}: {
  editor: Editor;
  enableComment?: boolean;
  meetingId?: number;
  organizationId?: number | null;
  topicId?: number | null;
  onCreateCommentUuid?: (uuid: string) => void;
}) {
  const ref = useRef<null | HTMLDivElement>(null);
  const setLink = useCallback(() => {
    toggleLink(editor);
  }, [editor]);

  const [updateArtifact] = useMutation(createOrUpdateArtifactMutation);
  const selectedText = editor.state.doc.textBetween(
    editor.state.selection.from,
    editor.state.selection.to,
    " "
  );
  const saveArtifact = (dataToSave: any) => {
    const variables = {
      organizationId,
      meetingId,
      topicId,
      uuid: uuidv4(),
      ...dataToSave,
    };
    updateArtifact({
      variables,
      onCompleted: (data) => {
        const artifact = data.createOrUpdateArtifact.artifact;
        if (dataToSave.artifactType === artifactType.actionItem) {
          editor
            .chain()
            .clearNodes()
            .insertActionItem({
              artifactId: artifact.id,
              ...artifact,
            })
            .run();
        } else if (dataToSave.artifactType === artifactType.decision) {
          editor
            .chain()
            .clearNodes()
            .insertDecision({
              artifactId: artifact.id,
              ...artifact,
            })
            .run();
        } else if (dataToSave.artifactType === artifactType.goal) {
          editor
            .chain()
            .clearNodes()
            .insertGoal({
              artifactId: artifact.id,
              ...artifact,
            })
            .run();
        }
      },
      onError: onNotificationErrorHandler(),
      refetchQueries: [getMeetingGoalsQuery],
      update: (_, response) => {
        refetchActionItemsInCollapsibleContainers(
          response.data.createOrUpdateArtifact.artifact
        );
      },
    });
  };

  const handleHideMenu = (tippy: any) => {
    // for some reason the tooltips on the menu buttons stay opened
    // when the editor text changes and the bubble menu is hidden.
    // The line below forces all tooltip/tippy instances to be closed...
    hideAll(); // can enable this as it hides the
    if (tippy) {
      tippy.setProps({ placement: "top" });
    }
  };

  // Detect if current selection has a comment already
  const { selection, doc } = editor.view.state;
  let selectionContainsACommentPos: null | number = null;
  if (!selection.empty) {
    doc.nodesBetween(
      editor.state.selection.from,
      editor.state.selection.to,
      (node, pos) => {
        if (
          !selectionContainsACommentPos &&
          node.marks.find((mark) => mark.type.name === "comment")
        ) {
          selectionContainsACommentPos = pos + 1;
        }
      },
      0
    );
  }

  const handleShowCommentForm = () => {
    if (selectionContainsACommentPos !== null) {
      const existingUuid = editor.getAttributes("comment")?.comment || null;
      if (existingUuid) {
        delay(() => {
          if (onCreateCommentUuid) onCreateCommentUuid(existingUuid);
        }, 50);
      }
    } else {
      const uuid = uuidv4();
      editor.chain().setComment(uuid).run();
      delay(() => {
        if (onCreateCommentUuid) onCreateCommentUuid(uuid);
      }, 50);
    }
  };

  const lastHighlight = store("editor:last-highlight-color");

  const NodeMenuItems = (
    <Menu
      portal
      align="start"
      transition={false}
      className="text-sm not-prose z-dropdown fs-unmask"
      menuButton={
        <button
          className={classNames(
            buttonClassName,
            "text-sm tracking-tight flex items-center gap-0.5"
          )}
          aria-label="Button node text"
        >
          {editor.isActive("orderedList")
            ? "Numbered list"
            : editor.isActive("bulletList")
            ? "Bullet list"
            : editor.isActive("codeBlock")
            ? "Code block"
            : editor.isActive("blockquote")
            ? "Quote"
            : "Paragraph"}
          <SelectorIcon className="h-4 w-4 text-gray-500" />
        </button>
      }
    >
      <MenuItem
        className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100 rounded-t-md"
        onClick={() => editor.chain().clearNodes().run()}
      >
        Paragraph
      </MenuItem>
      <MenuItem
        className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        Numbered list
      </MenuItem>
      <MenuItem
        className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      >
        Bullet list
      </MenuItem>
      <MenuItem
        className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100"
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
      >
        Quote
      </MenuItem>
      <MenuItem
        className="flex items-center gap-2 w-full text-left px-3 py-1.5 text-sm hover:bg-gray-100 rounded-b-md"
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
      >
        Code
      </MenuItem>
    </Menu>
  );
  const CommentMenuItem = enableComment && (
    <Tooltip text="Add a comment (Cmd+Alt+M)">
      <button
        type="button"
        onClick={handleShowCommentForm}
        className={classNames(
          buttonClassName,
          "flex gap-1 items-center text-sm tracking-tight",
          (editor.isActive("comment") ||
            selectionContainsACommentPos !== null) &&
            activeButtonClassName
        )}
        aria-label="Button comment text"
      >
        <RiMessage2Line className="shrink-0 h-4 w-4" />
        Comment
      </button>
    </Tooltip>
  );
  const MarkMenuItems = (
    <>
      <Tooltip text="Link (Cmd+K)">
        <button
          type="button"
          onClick={setLink}
          className={classNames(
            buttonClassName,
            editor.isActive("customLink") && activeButtonClassName
          )}
        >
          {editor.isActive("customLink") ? <RiLinkUnlink /> : <RiLink />}
        </button>
      </Tooltip>
      <Tooltip text="Bold (Cmd+B)">
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={classNames(
            buttonClassName,
            editor.isActive("bold") && activeButtonClassName
          )}
        >
          <RiBold />
        </button>
      </Tooltip>
      <Tooltip text="Italic (Cmd+I)">
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={classNames(
            buttonClassName,
            editor.isActive("italic") && activeButtonClassName
          )}
        >
          <RiItalic />
        </button>
      </Tooltip>

      <Tooltip text="Underline (Cmd+U)">
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={classNames(
            buttonClassName,
            editor.isActive("underline") && activeButtonClassName
          )}
        >
          <RiFontColor />
        </button>
      </Tooltip>

      <Tooltip text="Strikethrough (Cmd+Shift+X)">
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={classNames(
            buttonClassName,
            editor.isActive("strike") && activeButtonClassName
          )}
        >
          <RiStrikethrough />
        </button>
      </Tooltip>
    </>
  );
  const HighlightMenuItem = (
    <Menu
      portal
      align="start"
      transition={false}
      className="text-sm not-prose z-dropdown fs-unmask"
      menuButton={
        <button
          className={classNames(
            buttonClassName,
            "text-sm tracking-tight flex items-center gap-0.5"
          )}
          aria-label="Button highlight text"
        >
          <RiPaintBrushLine />
        </button>
      }
    >
      {lastHighlight && (
        <>
          <MenuItem
            className="py-0.5 px-2 text-2xs text-gray-400 uppercase"
            disabled
          >
            Last used
          </MenuItem>
          <MenuItem>
            <button
              className={classNames("flex items-center gap-2 w-full text-left")}
              onClick={() => {
                editor
                  .chain()
                  .focus()
                  .setHighlight({ color: lastHighlight.value })
                  .run();
              }}
            >
              <div
                className="h-3 w-3 border"
                style={{ background: lastHighlight.value }}
              />
              {lastHighlight.label}
            </button>
          </MenuItem>
        </>
      )}
      <MenuItem
        className="py-0.5 px-2 text-2xs text-gray-400 uppercase"
        disabled
      >
        Colors
      </MenuItem>
      <MenuItem>
        <button
          className="flex items-center gap-2 w-full text-left"
          onClick={() => {
            editor.chain().focus().unsetHighlight().run();
          }}
        >
          <div className="h-3 w-3 border" style={{ background: "#fff" }} />
          Default
        </button>
      </MenuItem>
      {highlightColours
        .map(({ label, value }) => ({
          label,
          value,
          onClick: () => {
            store("editor:last-highlight-color", { label, value });
            editor.chain().focus().setHighlight({ color: value }).run();
          },
        }))
        .map(({ label, value, onClick }) => (
          <MenuItem key={label}>
            <button
              className="flex items-center gap-2 w-full text-left"
              onClick={onClick}
            >
              <div className="h-3 w-3 border" style={{ background: value }} />
              {label}
            </button>
          </MenuItem>
        ))}
    </Menu>
  );

  const ClearMenuItem = (
    <Tooltip text="Clear formatting">
      <button
        type="button"
        className={classNames(buttonClassName, "last:rounded-r")}
        onClick={() => editor.chain().unsetAllMarks().clearNodes().run()}
      >
        <RiFormatClear />
      </button>
    </Tooltip>
  );
  const ConvertMenuItem = (
    <>
      <Tooltip text="Convert to action item">
        <button
          aria-label="Button convert to action item"
          type="button"
          className={classNames(
            buttonClassName,
            "last:rounded-r text-violet-600"
          )}
          onClick={() => {
            saveArtifact({
              title: selectedText,
              artifactType: artifactType.actionItem,
            });
          }}
        >
          <RiCheckboxLine />
        </button>
      </Tooltip>
      <Tooltip text="Convert to decision">
        <button
          type="button"
          className={classNames(
            buttonClassName,
            "last:rounded-r text-emerald-600"
          )}
          onClick={() => {
            saveArtifact({
              title: selectedText,
              artifactType: artifactType.decision,
              additionalFields: { decision: selectedText },
            });
          }}
        >
          <ArrowRightIcon className="h-4 w-4" />
        </button>
      </Tooltip>
      <Tooltip text="Convert to goal">
        <button
          type="button"
          className={classNames(
            buttonClassName,
            "last:rounded-r text-blue-600"
          )}
          onClick={() => {
            saveArtifact({
              title: selectedText,
              artifactType: artifactType.goal,
            });
          }}
        >
          <TbTarget />
        </button>
      </Tooltip>
    </>
  );

  const TableMenuItems = (
    <>
      {editor.commands.addColumnBefore && editor.can().addColumnBefore() && (
        <Tooltip text="Add column before">
          <button
            type="button"
            onClick={() => editor.chain().addColumnBefore().run()}
            className={classNames(tableButtonClassName, "rounded-l")}
          >
            <TbColumnInsertLeft className="h-5 w-5" />
          </button>
        </Tooltip>
      )}
      {editor.commands.addColumnAfter && editor.can().addColumnAfter() && (
        <Tooltip text="Add column after">
          <button
            type="button"
            onClick={() => editor.chain().addColumnAfter().run()}
            className={tableButtonClassName}
          >
            <TbColumnInsertRight className="h-5 w-5" />
          </button>
        </Tooltip>
      )}
      {editor.commands.deleteColumn && editor.can().deleteColumn() && (
        <Tooltip text="Delete column">
          <button
            type="button"
            onClick={() => editor.chain().deleteColumn().run()}
            className={tableButtonClassName}
          >
            <TbFreezeColumn className="h-5 w-5" />
          </button>
        </Tooltip>
      )}
      {editor.commands.addRowBefore && editor.can().addRowBefore() && (
        <Tooltip text="Add row before">
          <button
            type="button"
            onClick={() => editor.chain().addRowBefore().run()}
            className={tableButtonClassName}
          >
            <TbRowInsertTop className="h-5 w-5" />
          </button>
        </Tooltip>
      )}
      {editor.commands.addRowAfter && editor.can().addRowAfter() && (
        <Tooltip text="Add row after">
          <button
            type="button"
            onClick={() => editor.chain().addRowAfter().run()}
            className={tableButtonClassName}
          >
            <TbRowInsertBottom className="h-5 w-5" />
          </button>
        </Tooltip>
      )}
      {editor.commands.deleteRow && editor.can().deleteRow() && (
        <Tooltip text="Delete row">
          <button
            type="button"
            onClick={() => editor.chain().deleteRow().run()}
            className={tableButtonClassName}
          >
            <TbFreezeRow className="h-5 w-5" />
          </button>
        </Tooltip>
      )}
      {editor.commands.mergeCells && editor.can().mergeCells() && (
        <Tooltip text="Merge cells">
          <button
            type="button"
            onClick={() => editor.chain().mergeCells().run()}
            className={classNames(tableButtonClassName, "text-sm")}
          >
            Merge cells
          </button>
        </Tooltip>
      )}
      {editor.commands.splitCell && editor.can().splitCell() && (
        <Tooltip text="Split cell">
          <button
            type="button"
            onClick={() => editor.chain().splitCell().run()}
            className={classNames(tableButtonClassName, "text-sm")}
          >
            Split cell
          </button>
        </Tooltip>
      )}
      {editor.commands.deleteTable && editor.can().deleteTable() && (
        <Tooltip text="Delete table">
          <button
            type="button"
            onClick={() => {
              if (
                window.confirm("Are you sure you want to delete this table?")
              ) {
                editor.chain().deleteTable().run();
              }
            }}
            className={classNames(tableButtonClassName, "text-sm rounded-r")}
          >
            <TbTrash className="h-5 w-5" />
          </button>
        </Tooltip>
      )}
    </>
  );

  return (
    <div ref={ref}>
      <BubbleMenu
        editor={editor}
        tippyOptions={{
          appendTo: (ref2) => ref.current || ref2,
          maxWidth: "none",
          theme: "headless",
          onHidden: handleHideMenu,
        }}
        className="gap-px border bg-gray-200 flex rounded shadow"
        shouldShow={function shouldShowBubbleMenu({
          editor,
          view,
          state,
          from,
          to,
        }) {
          if (editor.isActive("table")) {
            return true;
          }
          if (
            editor.isActive("explorer") ||
            editor.isActive("decision") ||
            editor.isActive("action_item") ||
            editor.isActive("rating") ||
            editor.isActive("document") ||
            editor.isActive("goal")
          ) {
            return false;
          }
          const { doc, selection } = state;
          const { empty } = selection;

          // Sometime check for `empty` is not enough.
          // Doubleclick an empty paragraph returns a node size of 2.
          // So we check also for an empty text size.
          const isEmptyTextBlock =
            !doc.textBetween(from, to).length &&
            isTextSelection(state.selection);
          const hasEditorFocus = view.hasFocus();

          if (!hasEditorFocus || empty || isEmptyTextBlock) {
            return false;
          }
          return true;
        }}
      >
        {editor.isActive("table") ? (
          TableMenuItems
        ) : (
          <>
            {NodeMenuItems}
            {CommentMenuItem}
            {waffle.flag_is_active("tiptap-ai") ? (
              <AIMenuItems
                editor={editor}
                menuButton={
                  <button
                    className={classNames(
                      buttonClassName,
                      "text-sm tracking-tight flex items-center gap-0.5"
                    )}
                    aria-label="AI menu"
                  >
                    AI
                    <SparklesIcon className="h-4 w-4 text-purple-500" />
                  </button>
                }
              />
            ) : null}
            <div className="flex">
              {MarkMenuItems}
              {HighlightMenuItem}
              {ClearMenuItem}
              {ConvertMenuItem}
            </div>
          </>
        )}
      </BubbleMenu>
    </div>
  );
}
