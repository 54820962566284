import { useMutation, useQuery } from "@apollo/client";
import { max, omit, reduce } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CareerTrackRoleInput,
  CareerTrackRoleType,
  CompetencyAppliesTo,
  CompetencyCriteriaInput,
  CompetencyCriteriaUniqueness,
  CompetencyNode,
  GetOrganizationCareerTrackQuery,
  GetOrganizationCareerTrackQueryVariables,
  GetOrganizationCompetenciesQuery,
  GetOrganizationCompetenciesQueryVariables,
  OrganizationCompetencyCriteriaFragment,
  SaveOrgWideCareerTrackMutation,
  SaveOrgWideCareerTrackMutationVariables,
} from "types/graphql-schema";
import { v4 as uuidv4 } from "uuid";

import createOrUpdateCareerTrackMutation from "@apps/org-settings/graphql/create-or-update-career-track-mutation";
import getOrganizationCareerTrackQuery from "@apps/org-settings/graphql/get-organization-career-track-query";
import getOrganizationCompetenciesQuery from "@apps/org-settings/graphql/get-organization-competencies-query";
import useLabel from "@apps/use-label/use-label";
import { successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Heading from "@components/heading/heading";
import Input from "@components/input/input";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { useNotificationError } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";
import useUrlQueryParams from "@helpers/hooks/use-url-query-params";

import { roleColWidth, roleLevelIterator, stickyColWidth } from "../helpers";
import CareerTrackFormShadow from "../sticky-column-shadow";
import { CriteriaByCompetencyIdMap } from "../types";
import CareerTrackCompetencyRow from "./career-track-competency-row";
import RoleHeading from "./role-heading";

export type CareerTrackFormRole = CareerTrackRoleInput & {
  uuid: string;
  criteriaByCompetencyId: CriteriaByCompetencyIdMap;
};

const CareerTrackForm = ({ organizationId }: { organizationId: number }) => {
  const label = useLabel();
  const link = useLink();
  const { onError } = useNotificationError();
  const params = useParams<{ careerTrackId: string }>();
  const { teamStr } = useUrlQueryParams<{ teamStr?: string }>({
    teamStr: undefined,
  });
  const [levelCount, setLevelCount] = useState(1);

  const careerTrackId = useMemo(() => {
    if (params.careerTrackId?.match(/\d+/)) {
      return parseInt(params.careerTrackId);
    }
    return null;
  }, [params]);
  const isNew = careerTrackId === null;

  const [careerTrackData, setCareerTrackData] = useState<
    Omit<SaveOrgWideCareerTrackMutationVariables, "roles">
  >({
    title: "",
    organizationId,
  });
  const [associatedTeam, setAssociatedTeam] =
    useState<UserComboboxOption | null>(
      teamStr
        ? {
            id: parseInt(teamStr.split(":")[0]),
            title: teamStr.split(":")[1],
            type: UserComboboxOptionType.TEAM,
          }
        : null
    );
  const [roles, setRoles] = useState<CareerTrackFormRole[]>([]);

  const {
    data: competenciesData,
    loading: isCompetenciesLoading,
    refetch: refetchCompetencies,
  } = useQuery<
    GetOrganizationCompetenciesQuery,
    GetOrganizationCompetenciesQueryVariables
  >(getOrganizationCompetenciesQuery, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    variables: {
      organizationId,
    },
    onError,
  });

  const { data, loading: isCareerTrackLoading } = useQuery<
    GetOrganizationCareerTrackQuery,
    GetOrganizationCareerTrackQueryVariables
  >(getOrganizationCareerTrackQuery, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    variables: {
      careerTrackId: careerTrackId ?? 0,
    },
    skip: isNew,
    onError,
    onCompleted: (data) => {
      const roles = assertEdgesNonNull(data?.careerTrack?.roles);
      setCareerTrackData({
        ...data.careerTrack,
      });
      setRoles(
        roles.map((role) => {
          const roleCompetencies = assertEdgesNonNull(role.competencies);
          const criteriaByCompetencyId: CriteriaByCompetencyIdMap =
            roleCompetencies.reduce((acc, competency) => {
              const criteria = assertEdgesNonNull(competency.criteria).map(
                ({ id, content, level }) => ({
                  id,
                  content,
                  level,
                  uuid: uuidv4(),
                })
              );
              return {
                ...acc,
                [competency.id]: [...(acc[competency.id] ?? []), ...criteria],
              };
            }, {} as CriteriaByCompetencyIdMap);
          return {
            level: role.level,
            title: role.title,
            roleType: role.roleType,
            id: role.id,
            uuid: uuidv4(),
            criteriaByCompetencyId,
          };
        })
      );
      setLevelCount(assertNonNull(max(roles.map((role) => role.level))));
      setAssociatedTeam({
        id: assertNonNull(data.careerTrack?.team?.id),
        title: assertNonNull(data.careerTrack?.team?.title),
        type: UserComboboxOptionType.TEAM,
      });
    },
  });

  const [saveCareerTrack, { loading: isSaving }] = useMutation<
    SaveOrgWideCareerTrackMutation,
    SaveOrgWideCareerTrackMutationVariables
  >(createOrUpdateCareerTrackMutation, {
    onCompleted: () => {
      successNotificationVar({
        title: `Careeer track saved`,
      });
      link.redirect(`/settings/organization/${organizationId}/competencies`);
    },
  });

  const fullCompetenciesList: (Pick<
    CompetencyNode,
    "id" | "title" | "criteriaUniqueness" | "appliesTo"
  > &
    OrganizationCompetencyCriteriaFragment)[] = useMemo(() => {
    const competencyList = competenciesData
      ? assertEdgesNonNull(competenciesData?.competencies).filter(
          (competency) => {
            return (
              competency.appliesTo === CompetencyAppliesTo.AllRoles ||
              (competency.appliesToManagementRoles &&
                (roles.length === 0 ||
                  roles.some(
                    (role) => role.roleType === CareerTrackRoleType.Management
                  ))) ||
              (competency.appliesToIcRoles &&
                (roles.length === 0 ||
                  roles.some(
                    (role) => role.roleType === CareerTrackRoleType.Ic
                  ))) ||
              (associatedTeam &&
                assertEdgesNonNull(competency.appliesToTeams)
                  .map((team) => team.id)
                  .includes(associatedTeam.id)) ||
              (careerTrackId &&
                assertEdgesNonNull(competency.appliesToCareerTracks)
                  .map((track) => track.id)
                  .includes(careerTrackId))
            );
          }
        )
      : [];
    return competencyList.map((competency) => {
      return {
        ...competency,
        criteria: {
          __typename: "CompetencyCriteriaConnection",
          edges: competency.criteria.edges.map((criteriaNode) => {
            return {
              ...criteriaNode,
              __typename: "CompetencyCriteriaEdge",
              node: criteriaNode
                ? {
                    __typename: "CompetencyCriteriaNode",
                    id: assertNonNull(criteriaNode.node?.id),
                    content: assertNonNull(criteriaNode.node?.content),
                    level: assertNonNull(criteriaNode.node?.level),
                    uuid: uuidv4(),
                  }
                : null,
            };
          }),
        },
      };
    });
  }, [associatedTeam, careerTrackId, competenciesData, roles]);

  const handleSaveCompetency = useCallback(() => {
    saveCareerTrack({
      variables: {
        ...careerTrackData,
        roles: roles.map((item) => {
          const roleCriteria = reduce<
            CriteriaByCompetencyIdMap,
            CompetencyCriteriaInput[]
          >(
            item.criteriaByCompetencyId,
            (acc, criteria, criteriaId) => {
              const competency = fullCompetenciesList.find(
                (competency) => competency.id === parseInt(criteriaId)
              );
              if (
                !competency ||
                competency.criteriaUniqueness ===
                  CompetencyCriteriaUniqueness.SameAcrossRoles
              ) {
                return acc;
              }

              const newCriteria: CompetencyCriteriaInput[] = criteria.map(
                (criteriaItem) => ({
                  ...omit(criteriaItem, "uuid"),
                  competencyId: competency.id,
                })
              );

              return [...acc, ...newCriteria];
            },
            []
          );

          return {
            ...omit(item, "uuid", "criteriaByCompetencyId"),
            roleSpecificCriteria: roleCriteria,
          };
        }),
        careerTrackId: careerTrackId,
        teamId: assertNonNull(associatedTeam?.id),
      },
      onError,
    });
  }, [
    fullCompetenciesList,
    associatedTeam?.id,
    careerTrackData,
    careerTrackId,
    onError,
    roles,
    saveCareerTrack,
  ]);

  const { options, setQuery, query } = useUserComboboxQuery({
    types: [UserComboboxOptionType.TEAM],
    selected: associatedTeam ? associatedTeam : undefined,
  });

  const loading = isCareerTrackLoading || isCompetenciesLoading;
  const saveTooltip = useMemo(() => {
    if (!associatedTeam) return "Please select a team";
    if (roles.some((role) => !role.title || role.title?.trim() === ""))
      return "Every role must have a title";
    if (!careerTrackData.title?.trim()) return "Please enter a title";
    return null;
  }, [associatedTeam, careerTrackData.title, roles]);
  const canSave = !saveTooltip;

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <Heading small title={`${!isNew ? "Edit" : "New"} Career Track`} />
      </div>

      {loading && (
        <div className="flex justify-center mt-8">
          <Loading />
        </div>
      )}
      {!loading && !data?.careerTrack && !isNew && (
        <div className="flex justify-center mt-8">
          <div className="text-gray-500">Career Track not found</div>
        </div>
      )}

      {!loading && (data?.careerTrack || isNew) && (
        <div className="my-6">
          <div className="flex flex-col gap-8">
            <div className="flex">
              <div className="mt-5 text-gray-500 text-xs uppercase font-semibold w-48">
                Name
              </div>
              <Input
                aria-label="Competency title input"
                disabled={isSaving}
                className="w-fit flex-1 mt-1"
                value={careerTrackData.title ?? ""}
                onChange={(evt) => {
                  setCareerTrackData({
                    ...careerTrackData,
                    title: evt.target.value,
                  });
                }}
              />
            </div>
            <div className="flex">
              <div className="mt-3 text-gray-500 text-xs uppercase font-semibold w-48">
                Associated {label("team")}
              </div>
              <UserCombobox
                options={options}
                value={associatedTeam}
                onChangeValue={setAssociatedTeam}
                query={query}
                onChangeQuery={setQuery}
                placeholder={`${label("team", { capitalize: true })}: None`}
              />
            </div>
          </div>

          <div className="flex items-center justify-between mt-8 mb-4">
            <div className="font-bold">
              {`${label("competency", {
                capitalize: true,
                pluralize: true,
              })}, Responsibilities, and KPIs`}
            </div>
            <Button
              disabled={isSaving}
              text="Add Level"
              onClick={() => setLevelCount((levelCount) => levelCount + 1)}
            />
          </div>

          <div className="m-auto w-full">
            <div className="relative overflow-x-auto">
              <table className="text-sm table-fixed">
                <thead>
                  <tr>
                    <th
                      className={classNames(
                        stickyColWidth,
                        "border border-l-0 sticky left-0 bg-white"
                      )}
                    >
                      <CareerTrackFormShadow />
                    </th>
                    {roleLevelIterator(levelCount).map((level) => {
                      const role = roles.find((role) => role.level === level);
                      return (
                        <th
                          key={level}
                          className={classNames(
                            roleColWidth,
                            "p-2 border text-left font-normal align-top"
                          )}
                        >
                          <RoleHeading
                            level={level}
                            disabled={isSaving}
                            role={role}
                            onNewRole={() =>
                              setRoles((prev) => [
                                ...prev,
                                {
                                  uuid: uuidv4(),
                                  level,
                                  title: "",
                                  roleType: CareerTrackRoleType.Ic,
                                  criteriaByCompetencyId: {},
                                },
                              ])
                            }
                            onRoleChange={(newRole) => {
                              if (!role) {
                                return;
                              }
                              setRoles((prev) =>
                                prev.map((oldRole) => {
                                  if (oldRole.uuid === role.uuid) {
                                    return {
                                      ...oldRole,
                                      ...newRole,
                                    };
                                  }
                                  return oldRole;
                                })
                              );
                            }}
                          />
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-t border-b">
                    <td
                      className={classNames(
                        stickyColWidth,
                        "p-2 font-bold sticky left-0 bg-gray-100"
                      )}
                    >
                      {`${label("competency", {
                        capitalize: true,
                        pluralize: true,
                      })}`}
                      <CareerTrackFormShadow />
                    </td>
                    {roleLevelIterator(levelCount).map((level) => {
                      return (
                        <td
                          className={classNames(
                            roleColWidth,
                            "border bg-gray-100"
                          )}
                          key={level}
                        ></td>
                      );
                    })}
                  </tr>
                  {fullCompetenciesList.map((competency) => (
                    <CareerTrackCompetencyRow
                      key={competency.id}
                      onCompetenciesUpdated={refetchCompetencies}
                      organizationId={organizationId}
                      competency={competency}
                      levelCount={levelCount}
                      roles={roles}
                      onAddCriteria={(roleUuid, level) =>
                        setRoles((prev) =>
                          prev.map((prevRole) => {
                            if (roleUuid === prevRole.uuid) {
                              return {
                                ...prevRole,
                                criteriaByCompetencyId: {
                                  ...prevRole.criteriaByCompetencyId,
                                  [competency.id]: [
                                    ...(prevRole.criteriaByCompetencyId[
                                      competency.id
                                    ] ?? []),
                                    {
                                      level,
                                      content: "",
                                      uuid: uuidv4(),
                                    },
                                  ],
                                },
                              };
                            }
                            return prevRole;
                          })
                        )
                      }
                      onCriteriaChange={(roleUuid, criteriaUuid, content) =>
                        setRoles((prev) =>
                          prev.map((prevRole) => {
                            if (roleUuid === prevRole.uuid) {
                              return {
                                ...prevRole,
                                criteriaByCompetencyId: {
                                  ...prevRole.criteriaByCompetencyId,
                                  [competency.id]:
                                    prevRole.criteriaByCompetencyId[
                                      competency.id
                                    ]?.map((criteria) => {
                                      if (criteria.uuid === criteriaUuid) {
                                        return {
                                          ...criteria,
                                          content,
                                        };
                                      }
                                      return criteria;
                                    }),
                                },
                              };
                            }
                            return prevRole;
                          })
                        )
                      }
                      onDeleteCriteria={(roleUuid, criteriaUuid) =>
                        setRoles((prev) =>
                          prev.map((prevRole) => {
                            if (roleUuid === prevRole.uuid) {
                              return {
                                ...prevRole,
                                criteriaByCompetencyId: {
                                  ...prevRole.criteriaByCompetencyId,
                                  [competency.id]:
                                    prevRole.criteriaByCompetencyId[
                                      competency.id
                                    ]?.filter(
                                      (criteria) =>
                                        criteria.uuid !== criteriaUuid
                                    ),
                                },
                              };
                            }
                            return prevRole;
                          })
                        )
                      }
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex items-center justify-end gap-2 mt-4">
            <Button
              disabled={isSaving}
              theme={buttonTheme.text}
              text="Discard changes"
              to={`/settings/organization/${organizationId}/competencies`}
            />
            <Button
              disabled={isSaving || !canSave}
              theme="primary"
              tooltip={saveTooltip}
              text={`Save Career track`}
              onClick={handleSaveCompetency}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CareerTrackForm;
