import { useQuery } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";
import {
  GetMeetingByGoogleMeetUrlQueryQuery,
  GetMeetingByGoogleMeetUrlQueryQueryVariables,
} from "types/graphql-schema";

import Container from "@components/container/container";
import Error from "@components/error/error";
import GraphqlError from "@components/error/graphql-error";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import getMeetingByGoogleMeetUrlQuery from "../graphql/get-meeting-by-google-meet-url-query";
import Meeting from "./meeting";

const ExtensionMeetingContainer = ({
  meetingCode,
}: {
  meetingCode?: string;
}) => {
  const params = useParams<{ meetingGroupId: string; meetingId: string }>();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const queryParamGoogleMeetUrl = urlParams.get("googleMeetUrl");
  const googleMeetUrl =
    queryParamGoogleMeetUrl !== null
      ? queryParamGoogleMeetUrl
      : meetingCode !== undefined
      ? `https://meet.google.com/${meetingCode}`
      : "";

  const { loading, error, data } = useQuery<
    GetMeetingByGoogleMeetUrlQueryQuery,
    GetMeetingByGoogleMeetUrlQueryQueryVariables
  >(getMeetingByGoogleMeetUrlQuery, {
    skip: !!params.meetingGroupId && !!params.meetingId,
    fetchPolicy: "network-only",
    variables: { googleMeetUrl },
    onError: onNotificationErrorHandler(),
  });
  const meeting = data?.meeting;

  if (params.meetingGroupId && params.meetingId) {
    return <Meeting meetingId={Number(params.meetingId)} />;
  }

  // Renders
  if (error) {
    return (
      <Container>
        <div className="mx-4">
          <GraphqlError
            error={error}
            whatDidNotWork="The meeting could not be loaded."
          />
        </div>
      </Container>
    );
  }
  if (googleMeetUrl && !loading && !meeting) {
    return (
      <Container>
        <div className="mx-4">
          <Error title="No meetings associated with this Google Meet." />
        </div>
        <div className="mt-6 mx-4 flex justify-center">
          <AppLink
            to="/extension/meetings"
            className="text-blue-800 hover:underline"
          >
            View today's meetings
          </AppLink>
        </div>
      </Container>
    );
  }
  if (!data && loading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }
  if (!data || !meeting) {
    return null;
  }

  return <Meeting meetingId={data.meeting.id} />;
};

export default ExtensionMeetingContainer;
