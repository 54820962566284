import { useMutation } from "@apollo/client";
import {
  AnnotationIcon,
  DotsHorizontalIcon,
  ExternalLinkIcon,
  LinkIcon,
  TrashIcon,
  TrendingUpIcon,
} from "@heroicons/react/outline";
import {
  ClickEvent,
  Menu,
  MenuDivider,
  MenuItem,
  SubMenu,
} from "@szhsin/react-menu";
import copy from "copy-to-clipboard";
import { compact, noop } from "lodash";
import { Fragment, ReactNode, useCallback, useState } from "react";
import { FiCornerDownRight, FiCornerUpRight } from "react-icons/fi";
import { MdOutlinePlaylistRemove } from "react-icons/md";
import {
  TbArrowBarDown,
  TbArrowBarUp,
  TbListTree,
  TbPlaylistAdd,
  TbPlaylistX,
} from "react-icons/tb";
import { useLocation } from "react-router-dom";
import {
  AlignGoalMutationMutation,
  AlignGoalMutationMutationVariables,
  ArtifactType,
  CreatedArtifactFragmentFragment,
  GoalArtifactNode,
  GoalProgressType,
  GoalScope,
  GoalState,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import alignGoalMutation from "@apps/artifact-sidebar/graphql/align-goal-mutation";
import getArtifactSidebarQuery from "@apps/artifact-sidebar/graphql/get-artifact-sidebar-query";
import CheckinDialog from "@apps/checkin-dialog/checkin-dialog";
import CommentFormDialog from "@apps/comments/components/comment-form-dialog";
import GoalAlignmentDialogPicker from "@apps/goal-alignment-dialog-picker/goal-alignment-dialog-picker";
import { refreshAlignmentOfGoalIds } from "@apps/goal-alignment/helpers";
import useLabel from "@apps/use-label/use-label";
import cache, { errorNotificationVar } from "@cache/cache";
import { successNotificationVar } from "@cache/cache";
import GoalIcon from "@components/goal-icon/goal-icon";
import { useLink } from "@components/link/link";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { graphqlNone } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { getUrl, isGoalArtifactNode, toWithBackground } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import deleteArtifactMutation from "./graphql/delete-artifact-mutation";

type ArtifactDropdownArtifactType = {
  __typename: string;
  id: number;
  artifactType: ArtifactType;
  title?: string | null;
  progressType?: GoalProgressType;
  canDelete: {
    permission: boolean;
  };
  canUpdate: {
    permission: boolean;
  };
  scope?: GoalScope;
  state?: GoalState;
  parentGoalId?: number | null;
};

export const ArtifactDropdownAlignmentSubMenu = ({
  artifact,
  buttonRef,
}: {
  artifact: ArtifactDropdownArtifactType;
  buttonRef: HTMLElement | null;
}) => {
  const label = useLabel();
  const link = useLink();
  const location = useLocation<TFLocationState>();
  const artifactUrl = getUrl({
    artifactType: artifact.artifactType,
    artifactId: artifact.id,
  });
  const isEditableGoalArtifact =
    artifact.id &&
    artifact.artifactType === ArtifactType.Goal &&
    artifact.canUpdate.permission;

  const [isOpeningCreateSubGoalDialog, setIsOpeningCreateSubGoalDialog] =
    useState(false);
  const [isOpenGoalAlignmentDialog, setIsOpenGoalAlignmentDialog] = useState<
    "child" | "parent" | undefined
  >(undefined);

  const handleRevealChildGoalAlignmentPopover = () => {
    setIsOpenGoalAlignmentDialog("child");
  };

  const handleRevealParentGoalAlignmentPopover = () => {
    setIsOpenGoalAlignmentDialog("parent");
  };

  const handleCloseGoalAlignmentDialog = () => {
    setIsOpenGoalAlignmentDialog(undefined);
  };

  const handleCloseCreateArtifactDialog = (
    createdArtifact?: CreatedArtifactFragmentFragment
  ) => {
    setIsOpeningCreateSubGoalDialog(false);
    if (createdArtifact && isGoalArtifactNode(createdArtifact)) {
      refreshAlignmentOfGoalIds([
        artifact.id,
        createdArtifact?.parentGoal?.id,
        createdArtifact?.id,
      ]);
    }
  };

  const {
    confirm: confirmClearParentAlignment,
    ConfirmationDialog: ConfirmationDialogClearParentAlignment,
  } = useConfirm("Are you sure you want to remove this alignment?", "");
  const [updateGoal] = useMutation<
    AlignGoalMutationMutation,
    AlignGoalMutationMutationVariables
  >(alignGoalMutation);

  const handleClearParentAlignment = async () => {
    const oldParentGoalId = artifact.parentGoalId;
    const confirmed = await confirmClearParentAlignment();
    if (confirmed) {
      updateGoal({
        variables: {
          goalId: artifact.id,
          parentGoalId: graphqlNone,
        },
        refetchQueries: [getArtifactSidebarQuery],
        onError: onNotificationErrorHandler(),
        onCompleted: () => {
          refreshAlignmentOfGoalIds(compact([artifact.id, oldParentGoalId]));
        },
      });
    }
  };

  const handleClickLink = () => {
    link.redirect(
      toWithBackground({
        pathname: artifactUrl,
        location,
      })
    );
  };

  const fullAlignmentUrl = `/goal-alignment?goalId=${artifact.id}`;
  const handleClickViewFullAlignment = (e: ClickEvent) => {
    e.syntheticEvent.preventDefault();
    link.redirect(fullAlignmentUrl);
  };

  return (
    <>
      {isEditableGoalArtifact &&
        isOpenGoalAlignmentDialog === "child" &&
        buttonRef && (
          <GoalAlignmentDialogPicker
            alignmentType="child"
            artifactId={artifact.id}
            externalReferenceElement={buttonRef}
            onClose={handleCloseGoalAlignmentDialog}
          />
        )}
      {isEditableGoalArtifact &&
        isOpenGoalAlignmentDialog === "parent" &&
        buttonRef && (
          <GoalAlignmentDialogPicker
            alignmentType="parent"
            artifactId={artifact.id}
            externalReferenceElement={buttonRef}
            onClose={handleCloseGoalAlignmentDialog}
          />
        )}
      {isOpeningCreateSubGoalDialog && artifact.id && artifact.title && (
        <ArtifactCreationDialog
          formOptions={{
            artifactType: ArtifactType.Goal,
            parentGoal: {
              value: artifact.id,
              label: artifact.title,
              htmlLabel: (
                <div className="flex items-center gap-1">
                  <GoalIcon scope={artifact.scope} state={artifact.state} />{" "}
                  {artifact.title}
                </div>
              ),
            },
          }}
          onClose={handleCloseCreateArtifactDialog}
        />
      )}
      <ConfirmationDialogClearParentAlignment />

      <MenuItem
        contentEditable={false}
        onClick={handleRevealParentGoalAlignmentPopover}
      >
        <FiCornerUpRight className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
        Align this {label("goal")} to a parent
      </MenuItem>
      <MenuItem
        contentEditable={false}
        onClick={handleRevealChildGoalAlignmentPopover}
      >
        <FiCornerDownRight className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
        Align a sub {label("goal")} to this
      </MenuItem>

      <MenuDivider />
      <MenuItem
        contentEditable={false}
        onClick={() => setIsOpeningCreateSubGoalDialog(true)}
      >
        <TbPlaylistAdd className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
        Create a sub {label("goal")}
      </MenuItem>
      <MenuDivider />

      {isGoalArtifactNode(artifact) && artifact.parentGoalId && (
        <>
          <MenuItem
            contentEditable={false}
            onClick={handleClearParentAlignment}
          >
            <TbPlaylistX className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
            Unalign from parent
          </MenuItem>
          <MenuDivider />
        </>
      )}

      <MenuItem contentEditable={false} onClick={handleClickLink}>
        <ExternalLinkIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
        Edit alignment
      </MenuItem>
      <MenuDivider />

      <MenuItem
        href={fullAlignmentUrl}
        contentEditable={false}
        onClick={handleClickViewFullAlignment}
      >
        <TbListTree className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
        View full alignment for this {label("goal")}
      </MenuItem>
    </>
  );
};

const ArtifactDropdownMenu = ({
  artifact,
  portal = true,
  size = "6",
  className = "",
  isInWysiwyg = false,
  descriptionIsExpanded = false,
  children = null,
  onToggleDescription,
  onDelete = noop,
  onSavedComment = noop,
  onRemoveComment,
}: {
  artifact: ArtifactDropdownArtifactType;
  portal?: boolean;
  size?: string;
  className?: string;
  isInWysiwyg?: boolean;
  descriptionIsExpanded?: boolean;
  anchorPoint?: any;
  children?: ReactNode;
  onToggleDescription?: () => void;
  onToggleMenu?: (bool: boolean) => void;
  onDelete?: (obj?: { artifact: any }) => void;
  onSavedComment?: (comment: any) => void;
  onRemoveComment?: () => void;
}) => {
  const [buttonRef, setButtonRef] = useState<HTMLElement | null>(null);
  const isEditableGoalArtifact =
    artifact.id &&
    artifact.artifactType === ArtifactType.Goal &&
    artifact.canUpdate.permission;
  const label = useLabel();
  const artifactName = label(artifact.artifactType);
  const link = useLink();
  const location = useLocation<TFLocationState>();
  const artifactUrl = getUrl({
    artifactType: artifact.artifactType,
    artifactId: artifact.id,
  });
  const { ConfirmationDialog, confirm } = useConfirm(
    "Are you sure?",
    `Are you sure you want to delete this ${artifactName}?`
  );

  const [isOpeningCommentFormDialog, setIsOpeningCommentFormDialog] =
    useState(false);
  const [isOpeningCheckinDialog, setIsOpeningCheckinDialog] = useState(false);

  const handleOpenSidebar = () => {
    link.redirect(
      toWithBackground({
        pathname: artifactUrl,
        location,
      })
    );
  };
  const handleCopyLink = () => {
    copy(`${window.location.origin}${artifactUrl}`);
    successNotificationVar({
      title: "Url copied.",
    });
  };

  /* Mutations */
  const [deleteArtifact] = useMutation(deleteArtifactMutation, {
    onCompleted: () => {
      cache.evict({ id: cache.identify(artifact) });
      cache.gc();
      onDelete({ artifact });
    },
    onError: () => {
      errorNotificationVar({
        description: `This ${artifactName} could not be deleted.`,
      });
    },
  });

  const handleSavedComment = (comment: any) => {
    setIsOpeningCommentFormDialog(false);
    onSavedComment(comment);
  };

  const handleRemoveArtifactFromNotes = () => {
    onDelete();
  };

  /* Handler */
  const handleDeleteArtifact = useCallback(
    async (e: ClickEvent) => {
      const confirmation = await confirm();
      if (confirmation) {
        deleteArtifact({
          variables: { artifactId: artifact.id },
          optimisticResponse: {
            deleteArtifact: {
              artifact,
            },
          },
          update(cache) {
            const artifactCacheId = cache.identify(artifact);
            cache.evict({ id: artifactCacheId });
            const baseArtifactCacheId = cache.identify({
              __typename: "BaseArtifactNode",
              id: artifact.id,
            });
            cache.evict({ id: baseArtifactCacheId });
            cache.gc();
          },
          onError: onNotificationErrorHandler(),
        });
        e.stopPropagation = true;
      }
    },
    [artifact, confirm, deleteArtifact]
  );

  const items = (
    <>
      {artifact.id && (
        <>
          <MenuItem contentEditable={false} onClick={handleOpenSidebar}>
            <ExternalLinkIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
            Open
          </MenuItem>
          <MenuItem contentEditable={false} onClick={handleCopyLink}>
            <LinkIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
            Copy link
          </MenuItem>
          <MenuDivider />
        </>
      )}

      {artifact.id && artifact.artifactType === ArtifactType.Goal && (
        <>
          <MenuItem
            contentEditable={false}
            onClick={() => setIsOpeningCheckinDialog(true)}
          >
            <TrendingUpIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
            Update progress
          </MenuItem>
          <MenuDivider />
        </>
      )}

      {isEditableGoalArtifact && (
        <>
          <SubMenu
            label={
              <>
                <TbListTree className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
                Update alignment
              </>
            }
          >
            <ArtifactDropdownAlignmentSubMenu
              buttonRef={buttonRef}
              artifact={artifact}
            />
          </SubMenu>

          <MenuDivider />
        </>
      )}

      {isInWysiwyg && artifact.id && (
        <>
          <MenuItem
            contentEditable={false}
            onClick={() => setIsOpeningCommentFormDialog(true)}
          >
            <AnnotationIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
            Add a comment
          </MenuItem>
          {onRemoveComment && (
            <MenuItem contentEditable={false} onClick={onRemoveComment}>
              <AnnotationIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
              Remove comment
            </MenuItem>
          )}
          <MenuDivider />
        </>
      )}
      {artifact.id && onToggleDescription && (
        <>
          <MenuItem contentEditable={false} onClick={onToggleDescription}>
            {descriptionIsExpanded ? (
              <TbArrowBarUp className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
            ) : (
              <TbArrowBarDown className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
            )}{" "}
            {descriptionIsExpanded ? "Collapse" : "Expand"} description
          </MenuItem>
          <MenuDivider />
        </>
      )}
      {isInWysiwyg && (
        <MenuItem
          onClick={handleRemoveArtifactFromNotes}
          data-testid="artifact-dropdown-remove-from-notes"
        >
          <MdOutlinePlaylistRemove className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
          Remove from notes
        </MenuItem>
      )}
      {artifact.id && artifact.canDelete.permission && (
        <>
          <ConfirmationDialog />
          <MenuItem
            onClick={handleDeleteArtifact}
            data-testid="artifact-dropdown-delete"
          >
            <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
            Delete
          </MenuItem>
        </>
      )}
    </>
  );

  return (
    <Fragment>
      {isOpeningCommentFormDialog && (
        <CommentFormDialog
          artifactId={artifact.id}
          onClose={() => setIsOpeningCommentFormDialog(false)}
          onSavedComment={handleSavedComment}
        />
      )}
      {isOpeningCheckinDialog && artifact.progressType && (
        <CheckinDialog
          artifact={artifact as GoalArtifactNode}
          onClose={() => setIsOpeningCheckinDialog(false)}
        />
      )}
      <Menu
        portal={portal}
        align="end"
        transition={false}
        aria-label="Artifact dropdown menu list"
        className="text-sm not-prose z-dropdown fs-unmask"
        menuButton={
          <button
            className={classNames("text-gray-400 rounded", className)}
            data-testid="artifact-dropdown-button"
            aria-label="Artifact dropdown menu button"
            ref={setButtonRef}
          >
            <span className="sr-only">Open options</span>
            <DotsHorizontalIcon className={`h-${size} w-${size}`} />
          </button>
        }
      >
        {children}
        {items}
      </Menu>
    </Fragment>
  );
};
export default ArtifactDropdownMenu;
