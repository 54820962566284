import { useMutation } from "@apollo/client";
import { MenuItem } from "@szhsin/react-menu";
import { startsWith } from "lodash";
import { MouseEventHandler, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  IgnoreMeetingMutationMutation,
  IgnoreMeetingMutationMutationVariables,
} from "types/graphql-schema";

import { removeMeeting, removeMeetingGroup } from "@cache/remove-meeting";
import { useLink } from "@components/link/link";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { getUrl } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import ignoreMeetingMutation from "../graphql/ignore-meeting-mutation";

const IgnoreMenu = ({
  meeting,
  meetingGroup,
}: {
  meeting: {
    id: number;
    ignored: boolean;
  };
  meetingGroup: {
    id: number;
  };
}) => {
  const link = useLink();
  const location = useLocation();
  const ignored = !!meeting.ignored;
  const [ignoreMeeting] = useMutation<
    IgnoreMeetingMutationMutation,
    IgnoreMeetingMutationMutationVariables
  >(ignoreMeetingMutation, {
    update: (_, response) => {
      if (!response.data?.ignoreMeeting?.meeting?.ignored) {
        return;
      }
      if (meeting) {
        removeMeeting(meeting);
      }
      if (meetingGroup) {
        removeMeetingGroup(meetingGroup);
      }
      // if current pathname matches the meeting location
      // redirect to homepage
      if (
        startsWith(
          location.pathname,
          getUrl({ meetingId: meeting?.id, meetingGroupId: meetingGroup?.id })
        )
      ) {
        link.redirect("/");
      }
    },
  });

  const {
    ConfirmationDialog: ConfirmationIgnoreDialog,
    confirm: confirmIgnore,
  } = useConfirm(
    "Are you sure?",
    "Are you sure you want to hide this meeting? It will no longer appear by default in the app."
  );

  const handleIgnoreMeeting: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (e) => {
      e.preventDefault();
      const variables = { meetingId: meeting.id, ignore: !ignored };
      if (variables.ignore) {
        const confirmation = await confirmIgnore();
        if (confirmation) {
          ignoreMeeting({ variables, onError: onNotificationErrorHandler() });
        }
      } else {
        ignoreMeeting({ variables, onError: onNotificationErrorHandler() });
      }
    },
    [confirmIgnore, ignoreMeeting, ignored, meeting]
  );

  // Render
  return (
    <MenuItem className={"text-sm"}>
      <ConfirmationIgnoreDialog />
      <button onClick={handleIgnoreMeeting} className="flex items-center">
        {meeting.ignored ? "Mark as visible" : "Mark as hidden"}
      </button>
    </MenuItem>
  );
};

export default IgnoreMenu;
